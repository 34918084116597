/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import './App.css';
import { useEffect, useState } from 'react';
import Information from './Components/BLE/Information/Information';
import Setup from './Components/BLE/Setup/Setup';
import MQTTLanding from './Components/MQTT/MQTTLanding';
import MQTTConfig from './Components/MQTT/105/MQTTConfig';
import ModeSelect from './Components/Modes/SetMode';


// Define services for use by device
const deviceInfo = '00000000-0001-1337-1337-000000000005';
const automation = 0x1815;
const actions = '00000000-0002-1337-1337-000000000005';
const networkInfo = '00000000-0003-1337-1337-000000000005';
const nameUUID = '00000001-0001-1337-1337-000000000005';
const modelUUID = '00000002-0001-1337-1337-000000000005';
const serialUUID = '00000003-0001-1337-1337-000000000005';
const hardwareUUID = '00000004-0001-1337-1337-000000000005';
const firmwareUUID = '00000005-0001-1337-1337-000000000005';
const softwareUUID = '00000006-0001-1337-1337-000000000005';
const staticIpUUID = '00000008-0003-1337-1337-000000000005';
const ipUUID = '00000001-0003-1337-1337-000000000005';
const subUUID = '00000002-0003-1337-1337-000000000005';
const gatewayUUID = '00000003-0003-1337-1337-000000000005'.toLowerCase();
const dnsUUID = '00000004-0003-1337-1337-000000000005'.toLowerCase();
const macUUID = '00000005-0003-1337-1337-000000000005'.toLowerCase();
const brokerIPUUID = '0000000C-0001-1337-1337-000000000005'.toLowerCase();
const brokerPortUUID = '0000000D-0001-1337-1337-000000000005'.toLowerCase();
const brokerUserUUID = '0000000E-0001-1337-1337-000000000005'.toLowerCase();
const brokerPassUUID = '0000000F-0001-1337-1337-000000000005'.toLowerCase();
const aggUUID = '00000010-0001-1337-1337-000000000005';
const calibrateUUID = '00000011-0001-1337-1337-000000000005';
const convertUUID = '00000012-0001-1337-1337-000000000005';
const s0ModeUUID = '00000013-0001-1337-1337-000000000005';
const s0EdgeUUID = '00000014-0001-1337-1337-000000000005';
const s0PersistUUID = '00000015-0001-1337-1337-000000000005';
const s0PeriodUUID = '00000016-0001-1337-1337-000000000005';
const s0CalibrateUUID = '00000017-0001-1337-1337-000000000005';
const s0ConvertUUID = '00000018-0001-1337-1337-000000000005';
const s1ModeUUID = '00000019-0001-1337-1337-000000000005';
const s1EdgeUUID = '0000001A-0001-1337-1337-000000000005'.toLowerCase();
const s1PersistUUID = '0000001B-0001-1337-1337-000000000005'.toLowerCase();
const s1PeriodUUID = '0000001C-0001-1337-1337-000000000005'.toLowerCase();
const s1CalibrateUUID = '0000001D-0001-1337-1337-000000000005'.toLowerCase();
const s1ConvertUUID = '0000001E-0001-1337-1337-000000000005'.toLowerCase();
const s2DeadbandUUID = '0000001F-0001-1337-1337-000000000005'.toLowerCase();
const s2CalibrateUUID = '00000020-0001-1337-1337-000000000005';
const s2ConvertUUID = '00000021-0001-1337-1337-000000000005';
const s3DeadbandUUID = '00000022-0001-1337-1337-000000000005';
const s3CalibrateUUID = '00000023-0001-1337-1337-000000000005';
const s3ConvertUUID = '00000024-0001-1337-1337-000000000005';
const wlanStaticUUID = '00000025-0001-1337-1337-000000000005';
const wlanIpUUID = '00000026-0001-1337-1337-000000000005';
const wlanMaskUUID = '00000027-0001-1337-1337-000000000005';
const wlanGatewayUUID = '00000028-0001-1337-1337-000000000005';
const wlanDnsUUID = '00000029-0001-1337-1337-000000000005';
const wlanSSIDUUID = '0000002A-0001-1337-1337-000000000005'.toLowerCase();
const wlanPassUUID = '0000002B-0001-1337-1337-000000000005'.toLowerCase();
const wlanFallbackUUID = '0000002C-0001-1337-1337-000000000005'.toLowerCase();
const netTypeUUID = '00000006-0003-1337-1337-000000000005';
const netQualityUUID = '00000007-0003-1337-1337-000000000005';
const adoptionKeyUUID = '0000002D-0001-1337-1337-000000000005'.toLowerCase();
const mqttSSLUUID = `0000002E-0001-1337-1337-000000000005`.toLowerCase();

// Only find devices with SHARC as the start
const options = {
  filters: [
    { namePrefix: 'SHARC-' }
  ],
  optionalServices: [deviceInfo, automation, actions, networkInfo],
};
// Used for decoding characteristics
const decoder = new TextDecoder('utf-8');

// Encoding outbound Characteristics
let utf8Encode = new TextEncoder();

// Define device variable to store connected device
let connectedDevice;

var currentConnectedValue;
var currentConnectedUnits;
var currentConnectedTs;

function App() {

  const [connected, setConnected] = useState(false);
  const [whichSharc, setWhichSharc] = useState('');
  const [sharcInfo, setSharcInfo] = useState({});
  const [sensorData, setSensorData] = useState(null);
  const [unitsData, setUnitsData] = useState(null);
  const [tsData, setTsData] = useState(null);
  const [mode, setMode] = useState(null);
  const [brokerIP, setBrokerIP] = useState('wss.sharc.tech');
  const [brokerPort, setBrokerPort] = useState('443');
  const [brokerUser, setBrokerUser] = useState('');
  const [brokerPass, setBrokerPass] = useState('');
  const [deviceID, setDeviceID] = useState('');
  const [client, setClient] = useState({});
  const [useSSL, setUseSSL] = useState(true);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (mode === null) {
      setShowError(false);
      setErrorMessage('');
    }
  }, [mode]);


  // find device to connect to with established options
  function findDevice() {
    console.log('Searching for Device...');
    return navigator.bluetooth.requestDevice(options)
      .then(device => {
        connectedDevice = device;
        console.log('Device Connected');
        connectedDevice.addEventListener('gattserverdisconnected', onDisconnected);
        return device.gatt.connect();
      })
      .catch(error => { console.error(error); });
  }

  // If device disconnects set all to empty
  function onDisconnected(e) {
    console.log('Bluetooth Device Disconnected');
    setShowError(true);
    setErrorMessage('Bluetooth Device Disconnected');
    setConnected(false);
    connectedDevice = null;
    setSharcInfo({});
    setSensorData(null);
    setUnitsData(null);
    setTsData(null);
  }

  function saveConfig(agg, s0Mode, s0Edge, s0Persist, s0Period, s1Mode, s1Edge, s1Persist, s1Period, s2Conv, s3Conv, conv, cal) {
    if (whichSharc === '105') {
      return connectedDevice.gatt.connect()
        .then((item) => {
          console.log('Getting Services...');
          return item.getPrimaryService(deviceInfo);
        })
        .then((device) => {
          var newAgg = device.getCharacteristic(aggUUID);
          var newCalibrate = device.getCharacteristic(calibrateUUID);
          var newConvert = device.getCharacteristic(convertUUID);
          var newS0Mode = device.getCharacteristic(s0ModeUUID);
          var newS0Edge = device.getCharacteristic(s0EdgeUUID);
          var newS0Persist = device.getCharacteristic(s0PersistUUID);
          var newS0Period = device.getCharacteristic(s0PeriodUUID);
          var newS0Calibrate = device.getCharacteristic(s0CalibrateUUID);
          var newS0Convert = device.getCharacteristic(s0ConvertUUID);
          var newS1Mode = device.getCharacteristic(s1ModeUUID);
          var newS1Edge = device.getCharacteristic(s1EdgeUUID);
          var newS1Persist = device.getCharacteristic(s1PersistUUID);
          var newS1Period = device.getCharacteristic(s1PeriodUUID);
          var newS1Calibrate = device.getCharacteristic(s1CalibrateUUID);
          var newS1Convert = device.getCharacteristic(s1ConvertUUID);
          var newS2Convert = device.getCharacteristic(s2ConvertUUID);
          var newS3Convert = device.getCharacteristic(s3ConvertUUID);
          return Promise.all([newAgg, newCalibrate, newConvert, newS0Mode, newS0Edge, newS0Persist, newS0Period, newS0Calibrate, newS0Convert, newS1Mode, newS1Edge, newS1Persist, newS1Period, newS1Calibrate, newS1Convert, newS2Convert, newS3Convert]);
        })
        .then(([newAgg, newCalibrate, newConvert, newS0Mode, newS0Edge, newS0Persist, newS0Period, newS0Calibrate, newS0Convert, newS1Mode, newS1Edge, newS1Persist, newS1Period, newS1Calibrate, newS1Convert, newS2Convert, newS3Convert]) => {
          var s0conv;
          var s1conv;
          if (s0Mode === 'switch') {
            s0conv = "(v, '/', False)";
          } else {
            s0conv = "(v, 'count', False)";
          }
          if (s1Mode === 'switch') {
            s1conv = "(v, '/', False)";
          } else {
            s1conv = "(v, 'count', False)";
          }

          var aggVal = utf8Encode.encode(agg);
          var aggWrite = newAgg.writeValue(aggVal);
          var calVal = utf8Encode.encode(cal);
          var calWrite = newConvert.writeValue(calVal);
          var convVal = utf8Encode.encode(conv);
          var convWrite = newCalibrate.writeValue(convVal);
          var s0ModeVal = utf8Encode.encode(s0Mode);
          var s0ModeWrite = newS0Mode.writeValue(s0ModeVal);
          var s0EdgeVal = utf8Encode.encode(s0Edge);
          var s0EdgeWrite = newS0Edge.writeValue(s0EdgeVal);
          var s0PersistVal = utf8Encode.encode(s0Persist);
          var s0PersistWrite = newS0Persist.writeValue(s0PersistVal);
          var s0PeriodVal = utf8Encode.encode(s0Period);
          var s0PeriodWrite = newS0Period.writeValue(s0PeriodVal);
          var s0CalibrateVal = utf8Encode.encode("(v, False)");
          var s0CalibrateWrite = newS0Calibrate.writeValue(s0CalibrateVal);
          var s0ConvertVal = utf8Encode.encode(s0conv);
          var s0ConvertWrite = newS0Convert.writeValue(s0ConvertVal);
          var s1ModeVal = utf8Encode.encode(s1Mode);
          var s1ModeWrite = newS1Mode.writeValue(s1ModeVal);
          var s1EdgeVal = utf8Encode.encode(s1Edge);
          var s1EdgeWrite = newS1Edge.writeValue(s1EdgeVal);
          var s1PersistVal = utf8Encode.encode(s1Persist);
          var s1PersistWrite = newS1Persist.writeValue(s1PersistVal);
          var s1PeriodVal = utf8Encode.encode(s1Period);
          var s1PeriodWrite = newS1Period.writeValue(s1PeriodVal);
          var s1CalibrateVal = utf8Encode.encode("(v, False)");
          var s1CalibrateWrite = newS1Calibrate.writeValue(s1CalibrateVal);
          var s1ConvertVal = utf8Encode.encode(s1conv);
          var s1ConvertWrite = newS1Convert.writeValue(s1ConvertVal);
          var s2ConvertVal = utf8Encode.encode(s2Conv);
          var s2ConvertWrite = newS2Convert.writeValue(s2ConvertVal);
          var s3ConvertVal = utf8Encode.encode(s3Conv);
          var s3ConvertWrite = newS3Convert.writeValue(s3ConvertVal);
          return Promise.all([
            aggWrite, s0ModeWrite, s0EdgeWrite, s0PersistWrite, s0PeriodWrite, s0CalibrateWrite, s0ConvertWrite, s1ModeWrite, s1EdgeWrite, s1PersistWrite, s1PeriodWrite, s1CalibrateWrite, s1ConvertWrite, s2ConvertWrite, s3ConvertWrite, calWrite, convWrite
          ]);
        })
        .then(() => {
          console.log('Values changed succesfully');
        });
    }
  }
  // Get relevant info from the Sharc
  async function getInfo() {
    try {
      let deviceConnect = await connectedDevice.gatt.connect();
      let device = await deviceConnect.getPrimaryService(deviceInfo);
      let hwVersion = await device.getCharacteristic(hardwareUUID);
      let hwVersionChar = await hwVersion.readValue();
      let hwVersionVal = await decoder.decode(hwVersionChar);
      setWhichSharc(hwVersionVal);
      // if (hwVersionVal === '105') {
      let mqttSSL;
      try {
        let deviceConnect = await connectedDevice.gatt.connect();
        let device = await deviceConnect.getPrimaryService(deviceInfo);
        let adoptionKey = await device.getCharacteristic(adoptionKeyUUID);
        let adoptionKeyChar = await adoptionKey.readValue();
        let adoptionKeyVal = await decoder.decode(adoptionKeyChar);

        try {
          mqttSSL = await device.getCharacteristic(mqttSSLUUID);
          console.log('Has MQTT SSL Char');
        } catch (err) {
          console.log(err)
          console.log('Does not have MQTT SSL Char');
        }
        return connectedDevice.gatt.connect()
          .then((item) => {
            console.log('Getting Services...');
            var device = item.getPrimaryService(deviceInfo);
            var network = item.getPrimaryService(networkInfo);
            return Promise.all([
              device, network
            ]);
          })
          .then(([device, network]) => {
            console.log('Getting Characteristics...');
            var name = device.getCharacteristic(nameUUID);
            var model = device.getCharacteristic(modelUUID);
            var serial = device.getCharacteristic(serialUUID);
            var hardware = device.getCharacteristic(hardwareUUID);
            var firmware = device.getCharacteristic(firmwareUUID);
            var software = device.getCharacteristic(softwareUUID);
            var staticIp = network.getCharacteristic(staticIpUUID);
            var ip = network.getCharacteristic(ipUUID);
            var sub = network.getCharacteristic(subUUID);
            var gateway = network.getCharacteristic(gatewayUUID.toLowerCase());
            var dns = network.getCharacteristic(dnsUUID.toLowerCase());
            var mac = network.getCharacteristic(macUUID.toLowerCase());
            var brokerIP = device.getCharacteristic(brokerIPUUID.toLowerCase());
            var brokerPort = device.getCharacteristic(brokerPortUUID.toLowerCase());
            var brokerUser = device.getCharacteristic(brokerUserUUID.toLowerCase());
            var brokerPass = device.getCharacteristic(brokerPassUUID.toLowerCase());
            var agg = device.getCharacteristic(aggUUID);
            var calibrate = device.getCharacteristic(calibrateUUID);
            var convert = device.getCharacteristic(convertUUID);
            var s0Mode = device.getCharacteristic(s0ModeUUID);
            var s0Edge = device.getCharacteristic(s0EdgeUUID);
            var s0Persist = device.getCharacteristic(s0PersistUUID);
            var s0Period = device.getCharacteristic(s0PeriodUUID);
            var s0Calibrate = device.getCharacteristic(s0CalibrateUUID);
            var s0Convert = device.getCharacteristic(s0ConvertUUID);
            var s1Mode = device.getCharacteristic(s1ModeUUID);
            var s1Edge = device.getCharacteristic(s1EdgeUUID.toLowerCase());
            var s1Persist = device.getCharacteristic(s1PersistUUID.toLowerCase());
            var s1Period = device.getCharacteristic(s1PeriodUUID.toLowerCase());
            var s1Calibrate = device.getCharacteristic(s1CalibrateUUID.toLowerCase());
            var s1Convert = device.getCharacteristic(s1ConvertUUID.toLowerCase());
            var s2Deadband = device.getCharacteristic(s2DeadbandUUID.toLowerCase());
            var s2Calibrate = device.getCharacteristic(s2CalibrateUUID);
            var s2Convert = device.getCharacteristic(s2ConvertUUID);
            var s3Deadband = device.getCharacteristic(s3DeadbandUUID);
            var s3Calibrate = device.getCharacteristic(s3CalibrateUUID);
            var s3Convert = device.getCharacteristic(s3ConvertUUID);
            var wlanStatic = device.getCharacteristic(wlanStaticUUID);
            var wlanIp = device.getCharacteristic(wlanIpUUID);
            var wlanMask = device.getCharacteristic(wlanMaskUUID);
            var wlanGateway = device.getCharacteristic(wlanGatewayUUID);
            var wlanDns = device.getCharacteristic(wlanDnsUUID);
            var wlanSSID = device.getCharacteristic(wlanSSIDUUID.toLowerCase());
            var wlanPass = device.getCharacteristic(wlanPassUUID.toLowerCase());
            var wlanFallback = device.getCharacteristic(wlanFallbackUUID.toLowerCase());
            var netType = network.getCharacteristic(netTypeUUID);
            var netQuality = network.getCharacteristic(netQualityUUID);
            var adoptionKey = device.getCharacteristic(adoptionKeyUUID.toLowerCase());


            return Promise.all([
              name, model, serial, hardware, firmware, software, staticIp, ip, sub, gateway, dns, mac, brokerIP, brokerPort, brokerUser, brokerPass, agg, calibrate, convert, s0Mode, s0Edge, s0Persist, s0Period, s0Calibrate, s0Convert, s1Mode, s1Edge, s1Persist, s1Period, s1Calibrate, s1Convert, s2Deadband, s2Calibrate, s2Convert, s3Deadband, s3Calibrate, s3Convert, wlanStatic, wlanIp, wlanMask, wlanGateway, wlanDns, wlanSSID, wlanPass, wlanFallback, netType, netQuality, adoptionKey
            ]);
          })
          .then(([
            name, model, serial, hardware, firmware, software, staticIp, ip, sub, gateway, dns, mac, brokerIP, brokerPort, brokerUser, brokerPass, agg, calibrate, convert, s0Mode, s0Edge, s0Persist, s0Period, s0Calibrate, s0Convert, s1Mode, s1Edge, s1Persist, s1Period, s1Calibrate, s1Convert, s2Deadband, s2Calibrate, s2Convert, s3Deadband, s3Calibrate, s3Convert, wlanStatic, wlanIp, wlanMask, wlanGateway, wlanDns, wlanSSID, wlanPass, wlanFallback, netType, netQuality, adoptionKey
          ]) => {
            let readVals = [
              name, model, serial, hardware, firmware, software, staticIp, ip, sub, gateway, dns, mac, brokerIP, brokerPort, brokerUser, brokerPass, agg, calibrate, convert, s0Mode, s0Edge, s0Persist, s0Period, s0Calibrate, s0Convert, s1Mode, s1Edge, s1Persist, s1Period, s1Calibrate, s1Convert, s2Deadband, s2Calibrate, s2Convert, s3Deadband, s3Calibrate, s3Convert, wlanStatic, wlanIp, wlanMask, wlanGateway, wlanDns, wlanSSID, wlanPass, wlanFallback, netType, netQuality, adoptionKey
            ]
            if (mqttSSL) {
              readVals.push(mqttSSL)
            };
            readVals = readVals.map((item) => item.readValue());
            console.log('Getting Values...');
            return Promise.all(readVals);
          })
          .then((readVals) => {
            let values = readVals.map((item) => decoder.decode(item));
            return Promise.all(values);
          });
      } catch (e) {
        console.log("Sharc Outdated");
        return connectedDevice.gatt.connect()
          .then((item) => {
            console.log('Getting Services...');
            var device = item.getPrimaryService(deviceInfo);
            var network = item.getPrimaryService(networkInfo);
            return Promise.all([
              device, network
            ]);
          })
          .then(([device, network]) => {
            console.log('Getting Characteristics...');
            var name = device.getCharacteristic(nameUUID);
            var model = device.getCharacteristic(modelUUID);
            var serial = device.getCharacteristic(serialUUID);
            var hardware = device.getCharacteristic(hardwareUUID);
            var firmware = device.getCharacteristic(firmwareUUID);
            var software = device.getCharacteristic(softwareUUID);
            // var staticIp = network.getCharacteristic(staticIpUUID);
            var ip = network.getCharacteristic(ipUUID);
            var sub = network.getCharacteristic(subUUID);
            var gateway = network.getCharacteristic(gatewayUUID.toLowerCase());
            var dns = network.getCharacteristic(dnsUUID.toLowerCase());
            var mac = network.getCharacteristic(macUUID.toLowerCase());
            var brokerIP = device.getCharacteristic(brokerIPUUID.toLowerCase());
            var brokerPort = device.getCharacteristic(brokerPortUUID.toLowerCase());
            var brokerUser = device.getCharacteristic(brokerUserUUID.toLowerCase());
            var brokerPass = device.getCharacteristic(brokerPassUUID.toLowerCase());
            var agg = device.getCharacteristic(aggUUID);
            var calibrate = device.getCharacteristic(calibrateUUID);
            var convert = device.getCharacteristic(convertUUID);
            var s0Mode = device.getCharacteristic(s0ModeUUID);
            var s0Edge = device.getCharacteristic(s0EdgeUUID);
            var s0Persist = device.getCharacteristic(s0PersistUUID);
            var s0Period = device.getCharacteristic(s0PeriodUUID);
            var s0Calibrate = device.getCharacteristic(s0CalibrateUUID);
            var s0Convert = device.getCharacteristic(s0ConvertUUID);
            var s1Mode = device.getCharacteristic(s1ModeUUID);
            var s1Edge = device.getCharacteristic(s1EdgeUUID.toLowerCase());
            var s1Persist = device.getCharacteristic(s1PersistUUID.toLowerCase());
            var s1Period = device.getCharacteristic(s1PeriodUUID.toLowerCase());
            var s1Calibrate = device.getCharacteristic(s1CalibrateUUID.toLowerCase());
            var s1Convert = device.getCharacteristic(s1ConvertUUID.toLowerCase());
            var s2Deadband = device.getCharacteristic(s2DeadbandUUID.toLowerCase());
            var s2Calibrate = device.getCharacteristic(s2CalibrateUUID);
            var s2Convert = device.getCharacteristic(s2ConvertUUID);
            var s3Deadband = device.getCharacteristic(s3DeadbandUUID);
            var s3Calibrate = device.getCharacteristic(s3CalibrateUUID);
            var s3Convert = device.getCharacteristic(s3ConvertUUID);

            return Promise.all([
              name, model, serial, hardware, firmware, software,
              // staticIp,
              ip, sub, gateway, dns, mac, brokerIP, brokerPort, brokerUser, brokerPass, agg, calibrate, convert, s0Mode, s0Edge, s0Persist, s0Period, s0Calibrate, s0Convert, s1Mode, s1Edge, s1Persist, s1Period, s1Calibrate, s1Convert, s2Deadband, s2Calibrate, s2Convert, s3Deadband, s3Calibrate, s3Convert
            ]);
          })
          .then(([
            name, model, serial, hardware, firmware, software,
            // staticIp,
            ip, sub, gateway, dns, mac, brokerIP, brokerPort, brokerUser, brokerPass, agg, calibrate, convert, s0Mode, s0Edge, s0Persist, s0Period, s0Calibrate, s0Convert, s1Mode, s1Edge, s1Persist, s1Period, s1Calibrate, s1Convert, s2Deadband, s2Calibrate, s2Convert, s3Deadband, s3Calibrate, s3Convert
          ]) => {
            console.log('Getting Values...');
            let readVals = [
              name, model, serial, hardware, firmware, software,
              // staticIp,
              ip, sub, gateway, dns, mac, brokerIP, brokerPort, brokerUser, brokerPass, agg, calibrate, convert, s0Mode, s0Edge, s0Persist, s0Period, s0Calibrate, s0Convert, s1Mode, s1Edge, s1Persist, s1Period, s1Calibrate, s1Convert, s2Deadband, s2Calibrate, s2Convert, s3Deadband, s3Calibrate, s3Convert
            ].map((item) => item.readValue());
            return Promise.all(readVals);
          })
          .then((readVals) => {
            let values = readVals.map((item) => decoder.decode(item));
            return Promise.all(values);
          });
      }
      // } else if (hwVersionVal === '106') {

      // }
    } catch (err) {
      console.log(err);
    }
  }


  // Enable notifications for sensor data
  function getData(sensor, sensorUnits, sensorTs) {

    return connectedDevice.gatt.connect()
      .then((device) => {
        return device.getPrimaryService(automation)
          .then((service) => {
            const value = service.getCharacteristic(sensor);
            const units = service.getCharacteristic(sensorUnits);
            const ts = service.getCharacteristic(sensorTs);
            return Promise.all([value, units, ts]);
          })
          .then(([value, units, ts]) => {
            if (currentConnectedValue || currentConnectedUnits || currentConnectedTs) {
              currentConnectedValue.stopNotifications();
              currentConnectedUnits.stopNotifications();
              currentConnectedTs.stopNotifications();
            }

            currentConnectedValue = value;
            currentConnectedUnits = units;
            currentConnectedTs = ts;


            value.startNotifications();
            value.addEventListener('characteristicvaluechanged', (e) => {
              let data = decoder.decode(e.target.value);
              if (data?.length > 0) {
                setSensorData(data);
              } else {
                setSensorData('');
              }
            });
            value.readValue((data) => { setSensorData(data); });
            units.startNotifications();
            units.addEventListener('characteristicvaluechanged', (e) => {
              let data = decoder.decode(e.target.value);
              if (data?.length > 0) {
                setUnitsData(data);
              } else {
                setUnitsData('');
              }
            });
            units.readValue((data) => { setUnitsData(data); });
            ts.startNotifications();
            ts.addEventListener('characteristicvaluechanged', (e) => {
              let data = decoder.decode(e.target.value);
              if (data?.length > 0) {
                setTsData(data);
              } else {
                setTsData('');
              }
            });
            ts.readValue((data) => { setTsData(data); });
          });
      });
  }

  // Disconnect Sharc
  function disconnect() {
    console.log('Device Disconnected');
    setMode(null);
    return connectedDevice.gatt.disconnect();
  }

  // Change network configuration on device
  function changeNetType(type, lanFallback, ssid, pass) {
    var netType = type === 'WLAN' ? 1 : 0;
    if (netType === 0) {
      return connectedDevice.gatt.connect()
        .then((item) => {
          let action = item.getPrimaryService(actions);
          return Promise.all([action])
            .then(([action]) => {
              console.log('Writing Network values...');
              var newType = action.getCharacteristic('00000007-0002-1337-1337-000000000005');
              return Promise.all([newType]);
            })
            .then(([newType]) => {
              var typeValue = utf8Encode.encode(netType);
              var writeType = newType.writeValue(typeValue);
              return Promise.all([writeType]);
            })
            .then(() => {
              console.log('Wrote Successful...');
            });
        });
    } else if (pass) {
      return connectedDevice.gatt.connect()
        .then((item) => {
          let device = item.getPrimaryService(deviceInfo);
          let action = item.getPrimaryService(actions);
          return Promise.all([device, action])
            .then(([device, action]) => {
              console.log('Writing Network values...');
              var newType = action.getCharacteristic('00000007-0002-1337-1337-000000000005');
              var newSSID = device.getCharacteristic('0000002A-0001-1337-1337-000000000005'.toLowerCase());
              var newPass = device.getCharacteristic('0000002B-0001-1337-1337-000000000005'.toLowerCase());
              var fallback = device.getCharacteristic('0000002C-0001-1337-1337-000000000005'.toLowerCase());
              return Promise.all([newType, fallback, newSSID, newPass]);
            })
            .then(([newType, fallback, newSSID, newPass]) => {
              var typeValue = utf8Encode.encode(netType);
              var writeType = newType.writeValue(typeValue);
              var passValue = utf8Encode.encode(pass);
              var writePass = newPass.writeValue(passValue);
              var ssidValue = utf8Encode.encode(ssid);
              var writeSSID = newSSID.writeValue(ssidValue);
              var fallbackValue = utf8Encode.encode(lanFallback);
              var writeFall = fallback.writeValue(fallbackValue);
              return Promise.all([writeType, writeFall, writePass, writeSSID]);
            })
            .then(() => {
              console.log('Wrote Successful...');
            });
        });
    } else {
      return connectedDevice.gatt.connect()
        .then((item) => {
          let device = item.getPrimaryService(deviceInfo);
          let action = item.getPrimaryService(actions);
          return Promise.all([device, action])
            .then(([device, action]) => {
              console.log('Writing Network values...');
              var newType = action.getCharacteristic('00000007-0002-1337-1337-000000000005');
              var newSSID = device.getCharacteristic('0000002A-0001-1337-1337-000000000005'.toLowerCase());
              var fallback = device.getCharacteristic('0000002C-0001-1337-1337-000000000005'.toLowerCase());
              return Promise.all([newType, fallback, newSSID]);
            })
            .then(([newType, fallback, newSSID]) => {
              var typeValue = utf8Encode.encode(netType);
              var writeType = newType.writeValue(typeValue);
              var ssidValue = utf8Encode.encode(ssid);
              var writeSSID = newSSID.writeValue(ssidValue);
              var fallbackValue = utf8Encode.encode(lanFallback);
              var writeFall = fallback.writeValue(fallbackValue);
              return Promise.all([writeType, writeFall, writeSSID]);
            })
            .then(() => {
              console.log('Wrote Successful...');
            });
        });
    }
  }

  async function changeNetConfig(staticIp, ip, subnet, gateway, dns, type, ssid, pass, fallback) {
    try {
      let deviceConnect = await connectedDevice.gatt.connect();
      let device = await deviceConnect.getPrimaryService(deviceInfo);
      let wlanIp = await device.getCharacteristic('00000026-0001-1337-1337-000000000005');
      let wlanIpChar = await wlanIp.readValue();
      let wlanIpVal = await decoder.decode(wlanIpChar);
      if (pass) {
        return connectedDevice.gatt.connect()
          .then((item) => {
            let device = item.getPrimaryService(deviceInfo);
            return Promise.all([device])
              .then(([device]) => {
                console.log('Writing Network values...');
                var newPass = device.getCharacteristic('0000002B-0001-1337-1337-000000000005'.toLowerCase());
                return Promise.all([newPass]);
              })
              .then(([newPass]) => {
                var passValue = utf8Encode.encode(pass);
                var writePass = newPass.writeValue(passValue);
                return Promise.all([writePass]);
              })
              .then(() => {
                console.log('Wrote Successful...');
              });
          });
      }
      if (type === 'LAN' && (staticIp === '1' || staticIp === true || staticIp === 1)) {
        return connectedDevice.gatt.connect()
          .then((item) => {
            return item.getPrimaryService(deviceInfo)
              .then((service) => {
                console.log('Writing Network values...');
                var newStatic = service.getCharacteristic('00000007-0001-1337-1337-000000000005');
                var newIp = service.getCharacteristic('00000008-0001-1337-1337-000000000005');
                var newSub = service.getCharacteristic('00000009-0001-1337-1337-000000000005');
                var newGateway = service.getCharacteristic('0000000A-0001-1337-1337-000000000005'.toLowerCase());
                var newDns = service.getCharacteristic('0000000B-0001-1337-1337-000000000005'.toLowerCase());
                return Promise.all([newStatic, newIp, newSub, newGateway, newDns]);
              })
              .then(([newStatic, newIp, newSub, newGateway, newDns]) => {
                var staticValue = utf8Encode.encode(staticIp);
                var writeStatic = newStatic.writeValue(staticValue);
                var ipValue = utf8Encode.encode(ip);
                var writeIp = newIp.writeValue(ipValue);
                var subValue = utf8Encode.encode(subnet);
                var writeSub = newSub.writeValue(subValue);
                var gatewayValue = utf8Encode.encode(gateway);
                var writeGateway = newGateway.writeValue(gatewayValue);
                var dnsValue = utf8Encode.encode(dns);
                var writeDns = newDns.writeValue(dnsValue);
                return Promise.all([writeStatic, writeIp, writeSub, writeGateway, writeDns]);
              })
              .then(() => {
                console.log('Wrote Successful...');
              });
          });
      } else if (type === "WLAN" && (staticIp === '1' || staticIp === true || staticIp === 1)) {
        return connectedDevice.gatt.connect()
          .then((item) => {
            return item.getPrimaryService(deviceInfo)
              .then((service) => {
                console.log('Writing Network values...');
                var wlanStatic = service.getCharacteristic('00000025-0001-1337-1337-000000000005');
                var wlanIp = service.getCharacteristic('00000026-0001-1337-1337-000000000005');
                var wlanMask = service.getCharacteristic('00000027-0001-1337-1337-000000000005');
                var wlanGateway = service.getCharacteristic('00000028-0001-1337-1337-000000000005');
                var wlanDns = service.getCharacteristic('00000029-0001-1337-1337-000000000005');
                var wlanSSID = service.getCharacteristic('0000002A-0001-1337-1337-000000000005'.toLowerCase());
                var wlanFallback = service.getCharacteristic('0000002C-0001-1337-1337-000000000005'.toLowerCase());
                return Promise.all([wlanStatic, wlanIp, wlanMask, wlanGateway, wlanDns, wlanSSID, wlanFallback]);
              })
              .then(([wlanStatic, wlanIp, wlanMask, wlanGateway, wlanDns, wlanSSID, wlanFallback]) => {
                var staticValue = utf8Encode.encode(staticIp);
                var writeStatic = wlanStatic.writeValue(staticValue);
                var ipValue = utf8Encode.encode(ip);
                var writeIp = wlanIp.writeValue(ipValue);
                var subValue = utf8Encode.encode(subnet);
                var writeSub = wlanMask.writeValue(subValue);
                var gatewayValue = utf8Encode.encode(gateway);
                var writeGateway = wlanGateway.writeValue(gatewayValue);
                var dnsValue = utf8Encode.encode(dns);
                var writeDns = wlanDns.writeValue(dnsValue);
                var wlanSSIDValue = utf8Encode.encode(ssid);
                var writeSSID = wlanSSID.writeValue(wlanSSIDValue);
                var fallbackValue = utf8Encode.encode(fallback);
                var writeFallback = wlanFallback.writeValue(fallbackValue);
                return Promise.all([writeStatic, writeIp, writeSub, writeGateway, writeDns, writeSSID, writeFallback]);
              })
              .then(() => {
                console.log('Wrote Successful...');
              });
          });
      } else if ((staticIp === '0' || staticIp === false || staticIp === 0) && type === 'WLAN') {
        console.log('Not Static');
        return connectedDevice.gatt.connect()
          .then((item) => {
            return item.getPrimaryService(deviceInfo)
              .then((service) => {
                console.log('Writing Network values...');
                var wlanStatic = service.getCharacteristic('00000025-0001-1337-1337-000000000005');
                var wlanSSID = service.getCharacteristic('0000002A-0001-1337-1337-000000000005'.toLowerCase());
                return Promise.all([wlanStatic, wlanSSID]);
              })
              .then(([wlanStatic, wlanSSID]) => {
                var staticValue = utf8Encode.encode(staticIp);
                var writeStatic = wlanStatic.writeValue(staticValue);
                var wlanSSIDValue = utf8Encode.encode(ssid);
                var writeSSID = wlanSSID.writeValue(wlanSSIDValue);
                return Promise.all([writeStatic, writeSSID]);
              })
              .then(() => {
                console.log('Wrote Successful...');
              });
          });
      } else if ((staticIp === '0' || staticIp === false || staticIp === 0) && type === 'LAN') {
        return connectedDevice.gatt.connect()
          .then((item) => {
            return item.getPrimaryService(deviceInfo)
              .then((service) => {
                console.log('Writing Network values...');
                var newStatic = service.getCharacteristic('00000007-0001-1337-1337-000000000005');
                return Promise.all([newStatic]);
              })
              .then(([newStatic]) => {
                var staticValue = utf8Encode.encode(staticIp);
                var writeStatic = newStatic.writeValue(staticValue);
                return Promise.all([writeStatic]);
              })
              .then(() => {
                console.log('Wrote Successful...');
              });
          });
      }
    } catch (err) {
      console.log('Sharc Outdated');
      return connectedDevice.gatt.connect()
        .then((item) => {
          return item.getPrimaryService(deviceInfo)
            .then((service) => {
              console.log('Writing Network values...');
              var newStatic = service.getCharacteristic('00000007-0001-1337-1337-000000000005');
              var newIp = service.getCharacteristic('00000008-0001-1337-1337-000000000005');
              var newSub = service.getCharacteristic('00000009-0001-1337-1337-000000000005');
              var newGateway = service.getCharacteristic('0000000A-0001-1337-1337-000000000005'.toLowerCase());
              var newDns = service.getCharacteristic('0000000B-0001-1337-1337-000000000005'.toLowerCase());
              return Promise.all([newStatic, newIp, newSub, newGateway, newDns]);
            })
            .then(([newStatic, newIp, newSub, newGateway, newDns]) => {
              var staticValue = utf8Encode.encode(staticIp);
              var writeStatic = newStatic.writeValue(staticValue);
              var ipValue = utf8Encode.encode(ip);
              var writeIp = newIp.writeValue(ipValue);
              var subValue = utf8Encode.encode(subnet);
              var writeSub = newSub.writeValue(subValue);
              var gatewayValue = utf8Encode.encode(gateway);
              var writeGateway = newGateway.writeValue(gatewayValue);
              var dnsValue = utf8Encode.encode(dns);
              var writeDns = newDns.writeValue(dnsValue);
              return Promise.all([writeStatic, writeIp, writeSub, writeGateway, writeDns]);
            })
            .then(() => {
              console.log('Wrote Successful...');
            });
        });
    }
  }

  // Change MQTT Configuration on device
  function changeMqttConfig(ip, port, user, pass, useSSL) {
    try {
      return connectedDevice.gatt.connect()
        .then((item) => {
          return item.getPrimaryService(deviceInfo)
            .then((service) => {
              console.log('Writing MQTT values...');
              var newIp = service.getCharacteristic('0000000C-0001-1337-1337-000000000005'.toLowerCase());
              var newPort = service.getCharacteristic('0000000D-0001-1337-1337-000000000005'.toLowerCase());
              var newUser = service.getCharacteristic('0000000E-0001-1337-1337-000000000005'.toLowerCase());
              var newPass = service.getCharacteristic('0000000F-0001-1337-1337-000000000005'.toLowerCase());
              var newSSL = service.getCharacteristic(mqttSSLUUID)
              return Promise.all([newIp, newPort, newUser, newPass, newSSL]);
            })
            .then(([newIp, newPort, newUser, newPass, newSSL]) => {
              var ipValue = utf8Encode.encode(ip);
              var writeIp = newIp.writeValue(ipValue);
              var portValue = utf8Encode.encode(port);
              var writePort = newPort.writeValue(portValue);
              var userValue = utf8Encode.encode(user);
              var writeUser = newUser.writeValue(userValue);
              var passValue = utf8Encode.encode(pass);
              var writePass = newPass.writeValue(passValue);
              var sslValue = utf8Encode.encode(useSSL);
              var writeSSL = newSSL.writeValue(sslValue);
              return Promise.all([writeIp, writePort, writeUser, writePass, writeSSL]);
            })
            .then(() => {
              console.log('Wrote Successful...');
            });
        });
    } catch (err) {
      console.log('Use SSL not on BLE');
      return connectedDevice.gatt.connect()
        .then((item) => {
          return item.getPrimaryService(deviceInfo)
            .then((service) => {
              console.log('Writing MQTT values...');
              var newIp = service.getCharacteristic('0000000C-0001-1337-1337-000000000005'.toLowerCase());
              var newPort = service.getCharacteristic('0000000D-0001-1337-1337-000000000005'.toLowerCase());
              var newUser = service.getCharacteristic('0000000E-0001-1337-1337-000000000005'.toLowerCase());
              var newPass = service.getCharacteristic('0000000F-0001-1337-1337-000000000005'.toLowerCase());
              return Promise.all([newIp, newPort, newUser, newPass]);
            })
            .then(([newIp, newPort, newUser, newPass]) => {
              var ipValue = utf8Encode.encode(ip);
              var writeIp = newIp.writeValue(ipValue);
              var portValue = utf8Encode.encode(port);
              var writePort = newPort.writeValue(portValue);
              var userValue = utf8Encode.encode(user);
              var writeUser = newUser.writeValue(userValue);
              var passValue = utf8Encode.encode(pass);
              var writePass = newPass.writeValue(passValue);
              return Promise.all([writeIp, writePort, writeUser, writePass]);
            })
            .then(() => {
              console.log('Wrote Successful...');
            });
        });
    }


  }

  // Rebot device
  function rebootDevice(char) {
    return connectedDevice.gatt.connect()
      .then((item) => {
        return item.getPrimaryService(actions)
          .then((service) => {
            return service.getCharacteristic(char)
              .then((char) => {
                const reboot = utf8Encode.encode(1);
                return char.writeValue(reboot);
              })
              .then(() => {
                console.log('Rebooting Device...');
              });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Save Values to device and reboot
  function saveValues(char) {
    return connectedDevice.gatt.connect()
      .then((item) => {
        return item.getPrimaryService(actions)
          .then((service) => {
            return service.getCharacteristic(char)
              .then((char) => {
                const save = utf8Encode.encode(1);
                return char.writeValue(save);
              })
              .then(() => {
                console.log('Rebooting Device...');
              });
          });
      });
  }


  // Used to validate Ip addresses being inputted
  function validateIPaddress(inputText) {
    if (inputText.includes('.') && inputText !== '0.0.0.0') {
      return inputText;
    } else {
      // setErrorMessage("You have entered an invalid IP/DNS address!");
      // setShowError(true)
    }
  }

  // Used to validate Subnet Mask
  function validateSubnet(subnet) {
    var subFormat = /^((128|192|224|240|248|252|254)\.0\.0\.0)|(255\.(((0|128|192|224|240|248|252|254)\.0\.0)|(255\.(((0|128|192|224|240|248|252|254)\.0)|255\.(0|128|192|224|240|248|252|254)))))$/;
    if (subnet.match(subFormat)) {
      return subnet;
    }
    else {
      setErrorMessage("You have entered an invalid IP subnet mask!");
      setShowError(true);
    }
  }

  // Used to validate inputted port numbers
  function validatePort(port) {
    if (0 < Number(port) && Number(port) < 49151) {
      return port;
    } else {
      // return port
      setErrorMessage("You have entered an invalid port!");
      setShowError(true);
    }
  }
  useEffect(() => {
    if (mode === null) {
      setConnected(false);
    }
  }, [mode]);

  return (
    <>
      <div className="App">
        <div className="footer">
          <div>Sharc Studio Beta Ver 1125.1</div>
          <div>Check out <a href='https://ocean.sharc.tech' target='_blank' className='sharc-ocean-link'>SHARC Ocean</a> for monitoring multiple SHARC's at once!</div></div>

        {/* {!whichSharc && <SelectSharc setWhichSharc={setWhichSharc} />} */}
        {/* If no mode is selected, show the selector page */}
        {(!mode) && <ModeSelect setMode={setMode} setClient={setClient} />}

        {/* If there is no connected device and the user has selected BLE mode */}
        {(!connected && mode === 'ble') && <Setup connected={connected} setConnected={setConnected} sharcInfo={sharcInfo} setSharcInfo={setSharcInfo} getInfo={getInfo} findDevice={findDevice} getData={getData} disconnect={disconnect} changeNetConfig={changeNetConfig} changeMqttConfig={changeMqttConfig} rebootDevice={rebootDevice} validateIPaddress={validateIPaddress} validateSubnet={validateSubnet} validatePort={validatePort} setMode={setMode} setShowError={setShowError} showError={showError} errorMessage={errorMessage} mode={mode} setErrorMessage={setErrorMessage} />}

        {/* If the device is connected and the user has selected BLE mode */}
        {(connected && mode === 'ble') && <Information setConnected={setConnected} getInfo={getInfo} sharcInfo={sharcInfo} setSharcInfo={setSharcInfo} disconnect={disconnect} getData={getData} changeMqttConfig={changeMqttConfig} changeNetConfig={changeNetConfig} rebootDevice={rebootDevice} validateIPaddress={validateIPaddress} validateSubnet={validateSubnet} validatePort={validatePort} sensorData={sensorData} connectedDevice={connectedDevice} saveValues={saveValues} tsData={tsData} unitsData={unitsData} saveConfig={saveConfig} showError={showError} errorMessage={errorMessage} setShowError={setShowError} mode={mode} setErrorMessage={setErrorMessage} changeNetType={changeNetType} whichSharc={whichSharc} />}

        {/* If there is no connected device and the user has selected MQTT mode */}
        {(mode === 'mqtt' && !connected) && <MQTTConfig validateIPaddress={validateIPaddress} validatePort={validatePort} setMode={setMode} brokerIP={brokerIP} setBrokerIP={setBrokerIP} brokerPort={brokerPort} setBrokerPort={setBrokerPort} brokerUser={brokerUser} setBrokerUser={setBrokerUser} brokerPass={brokerPass} setBrokerPass={setBrokerPass} setConnected={setConnected} setDeviceID={setDeviceID} deviceID={deviceID} setClient={setClient} useSSL={useSSL} setUseSSL={setUseSSL} showError={showError} errorMessage={errorMessage} setShowError={setShowError} mode={mode} setErrorMessage={setErrorMessage} />}

        {/* If the device is connected and the user has selected MQTT mode */}
        {(mode === 'mqtt' && connected) && <MQTTLanding brokerIP={brokerIP} brokerPort={Number(brokerPort)} brokerUser={brokerUser} brokerPass={brokerPass} setConnected={setConnected} deviceID={deviceID} setMode={setMode} client={client} connected={connected} useSSL={useSSL} setUseSSL={setUseSSL} setShowError={setShowError} setErrorMessage={setErrorMessage} validateIPaddress={validateIPaddress} validatePort={validatePort} errorMessage={errorMessage} showError={showError} mode={mode} setWhichSharc={setWhichSharc} whichSharc={whichSharc} />}
      </div>
      {/* <footer>
        <div className="footer-div">Footer goes here</div>
      </footer> */}
    </>
  );
}

export default App;
