import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import { SteppedChart } from './Charts/SensorCharts'

let currentSensor = 's0'
// Displays charts for the sensor data
export default function Charts({ sensorData }) {

  const [sensor, setSensor] = useState('start')
  const [chartData, setChartData] = useState(new Array(499).fill({ time: undefined, value: '' }));
  const latestTempData = useRef(sensorData);
  // Every 10 milliseconds get the value of the sensor and add it to the charts data array
  // setTimeout(() => {
  //   if (sensorData) {
  //     var chartObj = { time: moment().format("h:mm:ss a"), value: sensorData?.v[sensor]?.v }
  //     if (chartData.length > 500) {
  //       chartData.shift()
  //     }
  //     setChartData(currentData => [...currentData, chartObj])
  //   }
  // }, 10)

  useEffect(() => {
    latestTempData.current = sensorData;
  }, [sensorData]);
  useEffect(() => {
    currentSensor = sensor;
  }, [sensor])
  // On load or sensor change fill the array with default values
  useEffect(() => {
    setChartData(new Array(499).fill({ time: undefined, value: '' }))
  }, [sensor])

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentSensor !== 'start') {

        setChartData((prevData) => {
          let newData = [...prevData, { time: undefined, value: latestTempData.current.v[currentSensor].v }];

          if (newData.length > 500) {
            newData = newData.slice(1);
          }

          return newData;
        });
      }
    }, 10); // Update every 10 milliseconds

    return () => clearInterval(intervalId);
  }, []);



  // On load or sensor change fill the array with default values
  // useEffect(() => {
  //   setChartData(new Array(499).fill({ time: undefined, value: '' }))
  // }, [sensor])

  // If the sensor has not been selected yet display the arrow to show to select which sensor is in use
  return (sensor !== 'start') ? (
    <div>
      <div id="sensors">
        <button className="sensor-buttons" onClick={(e) => {
          setSensor('s0')
        }} style={{ backgroundColor: sensor === 's0' ? '#0195f1' : '#e4e7eb', color: sensor === 's0' ? 'white' : 'black' }}>Digital PNP</button>
        <button className="sensor-buttons" onClick={(e) => {
          setSensor('s1')
        }} style={{ backgroundColor: sensor === 's1' ? '#0195f1' : '#e4e7eb', color: sensor === 's1' ? 'white' : 'black' }}>Digital NPN</button>
        <button className="sensor-buttons" onClick={(e) => {
          setSensor('s2')
        }} style={{ backgroundColor: sensor === 's2' ? '#0195f1' : '#e4e7eb', color: sensor === 's2' ? 'white' : 'black' }}>Analog 0-10v</button>
        <button className="sensor-buttons" onClick={(e) => {
          setSensor('s3')
        }} style={{ backgroundColor: sensor === 's3' ? '#0195f1' : '#e4e7eb', color: sensor === 's3' ? 'white' : 'black' }}>Analog 4-20ma</button>
      </div>
      <div id="charts">
        <div className="charts-container">
          {sensorData &&
            <div className="charts-information">
              {sensorData !== '' && <div className="charts-info-item"><div className="charts-value-header">Sequence</div>
                <div className="charts-value-info">{sensorData?.seq}</div></div>}
              <div className="charts-info-item"><div className="charts-value-header">Value</div>
                {sensorData?.v[sensor]?.v.toString() !== '' && <div className="charts-value-info">{sensorData?.v[sensor]?.v}</div>}</div>
              <div className="charts-info-item"><div className="charts-value-header">Units</div>
                {sensorData?.v[sensor]?.u && <div className="charts-value-info">{sensorData?.v[sensor]?.u}</div>}</div>
              {/* <div className="charts-info-item"><div className="charts-value-header">Condition</div>
                {sensorData?.v[sensor]?.c && <div className="charts-value-info">{sensorData?.v[sensor]?.c}</div>}</div> */}
            </div>}
          <SteppedChart chartData={chartData} />
          {/* If no sensor data just show the title of each row to not throw an error when trying to access a non-existent value */}
          {!sensorData &&
            <div className="charts-information">
              <div className="charts-info-item"><div className="charts-value-header">Sequence</div>
              </div>
              <div className="charts-info-item"><div className="charts-value-header">Value</div>
              </div>
              <div className="charts-info-item"><div className="charts-value-header">Units</div>
              </div>
              <div className="charts-info-item"><div className="charts-value-header">Condition</div>
              </div>
            </div>}
        </div>
      </div>
    </div>
  ) : (
    // If no sensor selected show arrow
    <>
      <div id="sensors">
        <button className="sensor-buttons" onClick={(e) => {
          setSensor('s0')
        }} style={{ backgroundColor: sensor === 's0' ? '#0195f1' : '#e4e7eb', color: sensor === 's0' ? 'white' : 'black' }}>Digital PNP</button>
        <button className="sensor-buttons" onClick={(e) => {
          setSensor('s1')
        }} style={{ backgroundColor: sensor === 's1' ? '#0195f1' : '#e4e7eb', color: sensor === 's1' ? 'white' : 'black' }}>Digital NPN</button>
        <button className="sensor-buttons" onClick={(e) => {
          setSensor('s2')
        }} style={{ backgroundColor: sensor === 's2' ? '#0195f1' : '#e4e7eb', color: sensor === 's2' ? 'white' : 'black' }}>Analog 0-10v</button>
        <button className="sensor-buttons" onClick={(e) => {
          setSensor('s3')
        }} style={{ backgroundColor: sensor === 's3' ? '#0195f1' : '#e4e7eb', color: sensor === 's3' ? 'white' : 'black' }}>Analog 4-20ma</button>
      </div>
      <div className="start-container">
        <div className="start-arrow">&uarr;</div>
        <div className="start-info">Please select which sensor you are currently using</div>
      </div>
    </>
  )
}