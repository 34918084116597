import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import { SteppedChart } from './Charts/SensorCharts'

let currentSensor = 's0'
// Displays charts for the sensor data
export default function Charts({ sensorData, sensorTopics }) {


  const [sensor, setSensor] = useState('s0')
  const [chartData, setChartData] = useState(new Array(499).fill({ time: undefined, value: '' }));
  const latestTempData = useRef(sensorData);
  useEffect(() => {
    latestTempData.current = sensorData;
  }, [sensorData]);
  useEffect(() => {
    currentSensor = sensor
  }, [sensor])
  // On load or sensor change fill the array with default values
  useEffect(() => {
    setChartData(new Array(499).fill({ time: undefined, value: '' }))
  }, [sensor])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setChartData((prevData) => {
        let newData = [...prevData, { time: undefined, value: latestTempData.current.v[currentSensor].v }];

        if (newData.length > 500) {
          newData = newData.slice(1);
        }

        return newData;
      });
    }, 10); // Update every 10 milliseconds

    return () => clearInterval(intervalId);
  }, []);

  // If the sensor has not been selected yet display the arrow to show to select which sensor is in use
  return (
    <div>
      <div id="sensors">
        <select value={sensor} onChange={(e) => setSensor(e.target.value)} className='sensor-selector'>
          {sensorTopics.map((sens) => {
            return (
              <option value={sens}>{sens}</option>
            )
          })}
        </select>
      </div>
      <div id="charts">
        <div className="charts-container">
          {sensorData &&
            <div className="charts-information">
              {sensorData !== '' && <div className="charts-info-item"><div className="charts-value-header">Sequence</div>
                <div className="charts-value-info">{sensorData?.v[sensor]?.seq}</div></div>}
              <div className="charts-info-item"><div className="charts-value-header">Value</div>
                {sensorData?.v[sensor]?.v.toString() !== '' && <div className="charts-value-info">{sensorData?.v[sensor]?.v}</div>}</div>
              <div className="charts-info-item"><div className="charts-value-header">Units</div>
                {sensorData?.v[sensor]?.u && <div className="charts-value-info">{sensorData?.v[sensor]?.u}</div>}</div>
              {/* <div className="charts-info-item"><div className="charts-value-header">Condition</div>
                {sensorData?.v[sensor]?.c && <div className="charts-value-info">{sensorData?.v[sensor]?.c}</div>}</div> */}
            </div>}
          <SteppedChart chartData={chartData} />
          {/* If no sensor data just show the title of each row to not throw an error when trying to access a non-existent value */}
          {!sensorData &&
            <div className="charts-information">
              <div className="charts-info-item"><div className="charts-value-header">Sequence</div>
              </div>
              <div className="charts-info-item"><div className="charts-value-header">Value</div>
              </div>
              <div className="charts-info-item"><div className="charts-value-header">Units</div>
              </div>
              <div className="charts-info-item"><div className="charts-value-header">Condition</div>
              </div>
            </div>}
        </div>
      </div>
    </div>
  )
}