import { useState, useEffect } from 'react'
import { Blocks } from 'react-loader-spinner'




// Used to update network information of the connected SHARC
export default function DeviceSettings({ setOpenDevice, sharcInfo, setSharcInfo, changeNetConfig, getInfo, validateIPaddress, validateSubnet, changeNetType, connectionType, setConnectionType, connectionQual, setConnectionQual, hasOutDatedInfo, setHasOutDatedInfo, staticIp, setStaticIp, ip, setIp, subnet, setSubnet, gateway, setGateway, dns, setDNS }) {

  // Needs to be able to switch between lan and WLAN
  const [originalConnectionType, setOriginalConnectionType] = useState(sharcInfo.connectionType)
  const [ssid, setSSID] = useState(sharcInfo.wlanSSID)
  const [lanFallbackS, setLanFallbackS] = useState(sharcInfo.wlanFallback)
  const [wifiPass, setWifiPass] = useState('')
  const [deviceSettingsLoading, setDeviceSettingsLoading] = useState(false);

  useEffect(() => {
    if (connectionType !== originalConnectionType) {
      setHasOutDatedInfo(true)
    } else {
      setHasOutDatedInfo(false)
    }
  }, [connectionType])

  return (
    <div id="device-modal" onMouseDown={(e) => {
      setOpenDevice(false)
    }}>
      <div className="modal-container" onMouseDown={(e) => {
        e.stopPropagation()
      }}>
        <div className="modal-header">
          <button className="exit hidden">X</button>
          <div className="modal-title">Edit Network Configuration</div>
          <button className="exit" onClick={(e) => {
            setOpenDevice(false)
          }}>X</button>
        </div>
        {deviceSettingsLoading &&
          <Blocks visible={true}
            height="150" width="150"
            ariaLabel="blocks-loading" wrapperStyle={{}} wrapperClass="blocks-wrapper" />
        }
        {!deviceSettingsLoading && <form className="modal-form" onSubmit={(e) => {
          e.preventDefault()
        }}>
          {connectionType && <div className="form-container">
            <label className="checkbox">Use Wifi?: <input type="checkbox" checked={connectionType === "WLAN"} onChange={(e) => {
              setConnectionType(e.target.checked ? "WLAN" : "LAN")
            }} ></input></label>
          </div>}
          {connectionType === "WLAN" && <>
            <div className="form-container">
              <label>SSID: <input type='text' value={ssid} onChange={(e) => {
                setSSID((e.target.value))
              }}></input></label>
            </div>
            <div className="form-container">
              <label>Change Password: <input type='password' value={wifiPass} onChange={(e) => {
                setWifiPass((e.target.value))
              }}></input></label>
            </div>
            <div className="form-container">
              <label>Lan Fallback (Seconds): <input type='number' value={lanFallbackS} onChange={(e) => {
                setLanFallbackS((e.target.value))
              }}></input></label>
            </div>
          </>}
          {(connectionType === originalConnectionType) && <div className="form-container">
            <label className="checkbox">Static IP: <input type="checkbox" checked={staticIp === 1 || staticIp === true || staticIp === '1'} onChange={(e) => {
              setStaticIp(e.target.checked ? 1 : 0)
            }} ></input></label>
          </div>}
          {((staticIp === 1 || staticIp === true || staticIp === '1') && connectionType === originalConnectionType) &&
            <><div className="form-container">
              <label>IP Address: <input type='text' value={ip} onChange={(e) => {
                setIp((e.target.value))
              }}></input></label>
            </div>
              <div className="form-container">
                <label>Subnet Mask: <input type='text' value={subnet} onChange={(e) => {
                  setSubnet((e.target.value))
                }}></input></label>
              </div>
              <div className="form-container">
                <label>Gateway: <input type='text' value={gateway} onChange={(e) => {
                  setGateway((e.target.value))
                }}></input></label>
              </div>
              <div className="form-container">
                <label>DNS: <input type='text' value={dns} onChange={(e) => {
                  setDNS((e.target.value))
                }}></input></label>
              </div></>}
          <div className="reboot-container">
            <div className="save"><button onClick={(e) => {
              setDeviceSettingsLoading(true)
              if (connectionType !== originalConnectionType) {
                console.log('Changed Net Type')
                changeNetType(connectionType, lanFallbackS, ssid, wifiPass).then(() => {
                  setOpenDevice(false)
                  setDeviceSettingsLoading(false)
                })
              } else if (wifiPass) {
                console.log('Change WiFi Pass')
                changeNetType(connectionType, lanFallbackS, ssid, wifiPass).then(() => {
                  setOpenDevice(false)
                  getInfo()
                    .then((res) => {
                      let update = {
                        name: res[0],
                        model: res[1],
                        serial: res[2],
                        hardware: res[3],
                        firmware: res[4],
                        software: res[5],
                        staticIp: res[6],
                        ip: res[7],
                        sub: res[8],
                        gateway: res[9],
                        dns: res[10],
                        mac: res[11],
                        brokerIP: res[12],
                        brokerPort: res[13],
                        brokerUser: res[14],
                        brokerPass: res[15],
                        agg: res[16],
                        calibrate: res[17],
                        convert: res[18],
                        s0Mode: res[19],
                        s0Edge: res[20],
                        s0Persist: res[21],
                        s0Period: res[22],
                        s0Calibrate: res[23],
                        s0Convert: res[24],
                        s1Mode: res[25],
                        s1Edge: res[26],
                        s1Persist: res[27],
                        s1Period: res[28],
                        s1Calibrate: res[29],
                        s1Convert: res[30],
                        s2Deadband: res[31],
                        s2Calibrate: res[32],
                        s2Convert: res[33],
                        s3Deadband: res[34],
                        s3Calibrate: res[35],
                        s3Convert: res[36],
                        wlanSSID: res[42],
                        wlanPass: res[43],
                        wlanFallback: res[44],
                        connectionType: res[45],
                        connectionQuality: res[46],
                        adoptionKey: res[47],
                        mqttUseSSL: res[48]

                      }
                      setSharcInfo(sharcInfo => ({
                        ...sharcInfo,
                        ...update
                      }))
                      setDeviceSettingsLoading(false)
                    })
                })
              }
              else if (validateIPaddress(ip.trim()) && validateSubnet(subnet.trim()) && validateIPaddress(gateway.trim()) && validateIPaddress(dns.trim()) && (staticIp === '1' || staticIp === true || staticIp === 1)) {
                console.log('Changed Net Info and Static')
                changeNetConfig(staticIp, validateIPaddress(ip.trim()), validateSubnet(subnet.trim()), validateIPaddress(gateway.trim()), validateIPaddress(dns.trim()), connectionType, ssid, wifiPass, lanFallbackS)
                  .then(() => {
                    setOpenDevice(false)
                    getInfo()
                      .then((res) => {
                        let update = {
                          name: res[0],
                          model: res[1],
                          serial: res[2],
                          hardware: res[3],
                          firmware: res[4],
                          software: res[5],
                          staticIp: res[6],
                          ip: res[7],
                          sub: res[8],
                          gateway: res[9],
                          dns: res[10],
                          mac: res[11],
                          brokerIP: res[12],
                          brokerPort: res[13],
                          brokerUser: res[14],
                          brokerPass: res[15],
                          agg: res[16],
                          calibrate: res[17],
                          convert: res[18],
                          s0Mode: res[19],
                          s0Edge: res[20],
                          s0Persist: res[21],
                          s0Period: res[22],
                          s0Calibrate: res[23],
                          s0Convert: res[24],
                          s1Mode: res[25],
                          s1Edge: res[26],
                          s1Persist: res[27],
                          s1Period: res[28],
                          s1Calibrate: res[29],
                          s1Convert: res[30],
                          s2Deadband: res[31],
                          s2Calibrate: res[32],
                          s2Convert: res[33],
                          s3Deadband: res[34],
                          s3Calibrate: res[35],
                          s3Convert: res[36],
                          wlanSSID: res[42],
                          wlanPass: res[43],
                          wlanFallback: res[44],
                          connectionType: res[45],
                          connectionQuality: res[46],
                          adoptionKey: res[47],
                          mqttUseSSL: res[48]

                        }
                        setSharcInfo(sharcInfo => ({
                          ...sharcInfo,
                          ...update
                        }))
                        setDeviceSettingsLoading(false)
                      })
                  })
              } else if (!staticIp || staticIp === '0') {
                console.log('No Static')
                changeNetConfig(staticIp, null, null, null, null, connectionType, ssid, null, null).then(() => {
                  setOpenDevice(false)
                  getInfo()
                    .then((res) => {
                      let update = {
                        name: res[0],
                        model: res[1],
                        serial: res[2],
                        hardware: res[3],
                        firmware: res[4],
                        software: res[5],
                        staticIp: res[6],
                        ip: res[7],
                        sub: res[8],
                        gateway: res[9],
                        dns: res[10],
                        mac: res[11],
                        brokerIP: res[12],
                        brokerPort: res[13],
                        brokerUser: res[14],
                        brokerPass: res[15],
                        agg: res[16],
                        calibrate: res[17],
                        convert: res[18],
                        s0Mode: res[19],
                        s0Edge: res[20],
                        s0Persist: res[21],
                        s0Period: res[22],
                        s0Calibrate: res[23],
                        s0Convert: res[24],
                        s1Mode: res[25],
                        s1Edge: res[26],
                        s1Persist: res[27],
                        s1Period: res[28],
                        s1Calibrate: res[29],
                        s1Convert: res[30],
                        s2Deadband: res[31],
                        s2Calibrate: res[32],
                        s2Convert: res[33],
                        s3Deadband: res[34],
                        s3Calibrate: res[35],
                        s3Convert: res[36],
                        wlanSSID: res[42],
                        wlanPass: res[43],
                        wlanFallback: res[44],
                        connectionType: res[45],
                        connectionQuality: res[46],
                        adoptionKey: res[47],
                        mqttUseSSL: res[48]

                      }
                      setSharcInfo(sharcInfo => ({
                        ...sharcInfo,
                        ...update
                      }))
                      setDeviceSettingsLoading(false)
                    })
                })
              } else {
                console.log('Invalid Values')
                alert('Invalid Values')
                setDeviceSettingsLoading(false)
                return;
              }
            }}>Update</button></div>
            <div className="reboot cancel"><button onClick={(e) => {
              setOpenDevice(false)
            }}>Cancel</button></div>
          </div>
        </form>}
      </div>
    </div>
  )
}