import Logo from '../../../assets/Logo.png'
import Sine from '../../../assets/Sine.png'
import Info from '../../../assets/InfoIcon.png'
import Power from '../../../assets/Power.png'
import Reboot from './Settings/Reboot'
import Save from './Settings/Save'
import DropDown from '../../../assets/DropDown.png'
import Help from '../../../assets/Help.webp'
import Refresh from '../../../assets/Refresh.png'
import { useState } from 'react'
import { Blocks } from 'react-loader-spinner'


// Buttons to navigate through the information page

export default function InfoHeader({ setSelectedTab, selectedTab, setConnected, disconnect, connectedDevice, rebootDevice, saveValues, dropDown, setDropDown, getInfo, setSharcInfo }) {
  const [openReboot, setOpenReboot] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  return (
    <div id="info-header">
      {openReboot && <Reboot setOpenReboot={setOpenReboot} setConnected={setConnected} rebootDevice={rebootDevice} />}
      {openSave && <Save setOpenSave={setOpenSave} setConnected={setConnected} saveValues={saveValues} />}
      <div className="info-header-container">
        <div style={{ padding: 0 }} className="logo">
          <a href="/"><img id="logo" src={Logo} alt="Sharc" style={{ width: '450px', boxSizing: 'borderBox' }} ></img></a>
        </div>
        <div style={{ padding: 0 }} className="logo">
          <div className="device-name-container"><div className="device-name-title">Connected Sharc </div><div className="device-name">{connectedDevice.name.split('-')[1]}</div></div>
        </div>
        <div className="options">
          <button className="header-buttons-container" disabled={refreshLoading} style={{ width: '60px' }} onClick={async (e) => {
            // setSelectedTab('info')
            setRefreshLoading(true)
            let res = await getInfo()
            let update = {
              name: res[0],
              model: res[1],
              serial: res[2],
              hardware: res[3],
              firmware: res[4],
              software: res[5],
              staticIp: res[6],
              ip: res[7],
              sub: res[8],
              gateway: res[9],
              dns: res[10],
              mac: res[11],
              brokerIP: res[12],
              brokerPort: res[13],
              brokerUser: res[14],
              brokerPass: res[15],
              agg: res[16],
              calibrate: res[17],
              convert: res[18],
              s0Mode: res[19],
              s0Edge: res[20],
              s0Persist: res[21],
              s0Period: res[22],
              s0Calibrate: res[23],
              s0Convert: res[24],
              s1Mode: res[25],
              s1Edge: res[26],
              s1Persist: res[27],
              s1Period: res[28],
              s1Calibrate: res[29],
              s1Convert: res[30],
              s2Deadband: res[31],
              s2Calibrate: res[32],
              s2Convert: res[33],
              s3Deadband: res[34],
              s3Calibrate: res[35],
              s3Convert: res[36],
              wlanSSID: res[42],
              wlanPass: res[43],
              wlanFallback: res[44],
              connectionType: res[45],
              connectionQuality: res[46],
              adoptionKey: res[47],
              mqttUseSSL: res[48]

            }
            setSharcInfo(sharcInfo => ({
              ...sharcInfo,
              ...update
            }))
            setRefreshLoading(false)

          }}>
            {!refreshLoading ? <img className="header-buttons" src={Refresh} alt="Refresh"></img> : (
              <div className="loading-container">
                <Blocks visible={true}
                  // height="150" width="150"
                  ariaLabel="blocks-loading" wrapperStyle={{}} wrapperClass="blocks-wrapper" />
              </div>
            )}
          </button>
          <button className="header-buttons-container" style={{ width: '60px', boxShadow: selectedTab === 'info' ? '0 0 0 2px #24a0ed' : null }} onClick={(e) => {
            setSelectedTab('info')
          }}><img className="header-buttons" src={Info} alt="Info"></img></button>
          <button className="header-buttons-container" style={{ width: '60px', boxShadow: selectedTab === 'charts' ? '0 0 0 2px #24a0ed' : null }} onClick={(e) => {
            setSelectedTab('charts')
          }}><img className="header-buttons" src={Sine} alt="Sine"></img></button>
          <a href="https://github.com/SharcTech/sharc-support" target="_blank" rel="noreferrer"><button className="header-buttons-container" style={{ width: '60px', boxShadow: selectedTab === 'help' ? '0 0 0 2px #24a0ed' : null }}><img className="header-buttons" src={Help} alt="Help"></img></button></a>
          <div>
            <div className="drop-down-container">
              <button className="header-buttons-container" style={{ width: '60px', borderRight: '1px solid black', borderRadius: '0px' }} onClick={(e) => {
                setDropDown(false)
                setOpenSave(true)
              }} alt="Disconnect MQTT"><img className="header-buttons power" src={Power} alt="Power"></img></button>
              <button className="drop-down-button" onClick={(e) => {
                setDropDown(!dropDown)
              }}>
                <img src={DropDown} style={{ height: '10px', justifyContent: 'flex-end', display: 'flex' }} alt="Arrow"></img>
              </button>
            </div>
            {dropDown === true &&
              <div className="options-drop-down">
                <button className="drop-down-option" onClick={() => {
                  setDropDown(false)
                  setOpenSave(true)
                }}>Save and Reboot</button>
                <button className="drop-down-option" onClick={() => {
                  setDropDown(false)
                  setOpenReboot(true)
                }}>Discard and Reboot</button>
                <button className="drop-down-option" onClick={() => {
                  disconnect()
                }}>Disconnect</button>
              </div>}
          </div>
        </div>
      </div>
    </div>
  )
}