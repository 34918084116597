import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import Paho from 'paho-mqtt';
import MQTTError from '../MQTTError';


// This is used to change what MQTT broker the device connects to
export default function ChangeMQTT({ setOpenChangeMQTT, deviceId, client, validatePort, validateIPaddress, errorMessage, showError, setShowError, setErrorMessage, mqttUser, mqttAddress, mqttPort, setMQTTUser, setMQTTAddress, setMQTTPort, setMQTTPass, mqttPass, useSSL, setUseSSL }) {
  const [newMQTTUser, setNewMQTTUser] = useState(mqttUser);
  const [newMQTTPass, setNewMQTTPass] = useState(mqttPass);
  const [newMQTTAddress, setNewMQTTAddress] = useState(mqttAddress);
  const [newMQTTPort, setNewMQTTPort] = useState(mqttPort);
  const [newUseSSL, setNewUseSSL] = useState(useSSL)


  return (
    <div id="mqtt-modal" onMouseDown={(e) => {
      setOpenChangeMQTT(false)
    }}>
      {showError && <MQTTError showError={showError} errorMessage={errorMessage} setShowError={setShowError} step={'Error Changing MQTT'} setErrorMessage={setErrorMessage} />}

      <div className="modal-container" onMouseDown={(e) => {
        e.stopPropagation()
      }}>
        <div className="modal-header">
          <button className="exit hidden">X</button>
          <div className="modal-title">Edit MQTT Configuration</div>
          <button className="exit" onClick={(e) => {
            setOpenChangeMQTT(false)
          }}>X</button>
        </div>
        <form className="modal-form">
          <div className="form-container">
            <label>Broker IP: <input type='text' value={newMQTTAddress === 'N/A' ? '' : newMQTTAddress} onChange={(e) => {
              setNewMQTTAddress(e.target.value)
            }}></input></label>
          </div>
          <div className="form-container">
            <label>Broker Port: <input type='text' value={newMQTTPort === 'N/A' ? '' : newMQTTPort} onChange={(e) => {
              setNewMQTTPort(e.target.value)
            }}></input></label>
          </div>
          <div className="form-container">
            <label>Broker Username: <input type='username' value={newMQTTUser === 'N/A' ? '' : newMQTTUser} onChange={(e) => {
              setNewMQTTUser(e.target.value)
            }}></input></label>
          </div>
          <div className="form-container">
            <label>Broker Password: <input type='password' value={newMQTTPass} onChange={(e) => {
              setNewMQTTPass(e.target.value)
            }}></input></label>
          </div>
          <label>Use SSL: <input type='checkbox' checked={newUseSSL} onChange={(e) => {
            setNewUseSSL(e.target.checked)
          }}></input></label>
          <div className='reboot-container'>
            <div className="save"><button onClick={(e) => {
              e.preventDefault()
              if (validateIPaddress(newMQTTAddress) && validatePort(newMQTTPort)) {
                let message = new Paho.Message(`{
                  "id": "${uuidv4()}",
                  "v": {
                    "mqtt.broker.address": "${newMQTTAddress}",
                    "mqtt.broker.port": ${newMQTTPort},
                    "mqtt.broker.user": "${newMQTTUser === 'N/A' ? '' : newMQTTUser}",
                    "mqtt.broker.pass": "${newMQTTPass}",
                    "mqtt.broker.ssl": ${newUseSSL}
                    }
                    }`)
                message.destinationName = `sharc/${deviceId}/cmd/cfg`
                client.send(message)
                setMQTTAddress(newMQTTAddress);
                setMQTTUser(newMQTTUser);
                setMQTTPort(newMQTTPort);
                setMQTTPass(newMQTTPass);
                setUseSSL(newUseSSL)
                setOpenChangeMQTT(false)
              }
            }}>Update</button></div>
            <div className="reboot cancel"><button onClick={(e) => {
              setOpenChangeMQTT(false)
            }}>Cancel</button></div>
          </div>
        </form>
      </div>
    </div>
  )
}