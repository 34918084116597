import { useState } from 'react'
import MQTTError from '../../../MQTT/105/MQTTError'
import { Blocks } from 'react-loader-spinner'

// Used to update MQTT Settings of the connected SHARC
export default function MQTTSettings({ setOpenMQTT, sharcInfo, setSharcInfo, changeMqttConfig, getInfo, validateIPaddress, validatePort, showError, errorMessage, setShowError, setErrorMessage }) {

  const [brokerIP, setBrokerIP] = useState(sharcInfo.brokerIP)
  const [brokerPort, setBrokerPort] = useState(sharcInfo.brokerPort)
  const [brokerUser, setBrokerUser] = useState(sharcInfo.brokerUser)
  const [brokerPass, setBrokerPass] = useState('')
  const [useSSL, setUseSSL] = useState(sharcInfo.mqttUseSSL ? sharcInfo.mqttUseSSL === '1' : false);
  const [mqttSettingsLoading, setMQTTSettingsLoading] = useState(false)

  return (
    <div id="mqtt-modal" onMouseDown={(e) => {
      setOpenMQTT(false)
    }}>
      {showError && <MQTTError showError={showError} errorMessage={errorMessage} setShowError={setShowError} step={'Error Changing MQTT'} setErrorMessage={setErrorMessage} />}

      <div className="modal-container" onMouseDown={(e) => {
        e.stopPropagation()
      }}>
        <div className="modal-header">
          <button className="exit hidden">X</button>
          <div className="modal-title">Edit MQTT Configuration</div>
          <button className="exit" onClick={(e) => {
            setOpenMQTT(false)
          }}>X</button>
        </div>
        {mqttSettingsLoading &&
          <Blocks visible={true}
            height="150" width="150"
            ariaLabel="blocks-loading" wrapperStyle={{}} wrapperClass="blocks-wrapper" />
        }
        {!mqttSettingsLoading && <form className="modal-form">
          <div className="form-container">
            <label>Broker IP: <input type='text' value={brokerIP} onChange={(e) => {
              setBrokerIP(e.target.value)
            }}></input></label>
          </div>
          <div className="form-container">
            <label>Broker Port: <input type='text' value={brokerPort} onChange={(e) => {
              setBrokerPort(e.target.value)
            }}></input></label>
          </div>
          <div className="form-container">
            <label>Broker Username: <input type='username' value={brokerUser} onChange={(e) => {
              setBrokerUser(e.target.value)
            }}></input></label>
          </div>
          <div className="form-container">
            <label>Broker Password: <input type='password' value={brokerPass} onChange={(e) => {
              setBrokerPass(e.target.value)
            }}></input></label>
          </div>
          {sharcInfo.mqttUseSSL && <label>Use SSL: <input type='checkbox' checked={useSSL} onChange={(e) => {
            setUseSSL(e.target.checked)
          }}></input></label>}
          <div className='reboot-container'>
            <div className="save"><button onClick={(e) => {
              e.preventDefault()
              setMQTTSettingsLoading(true)
              // If the user entered information is valid then update the SHARC with that information
              if (validateIPaddress(brokerIP.trim()) && validatePort(brokerPort.trim())) {

                changeMqttConfig(validateIPaddress(brokerIP.trim()), validatePort(brokerPort.trim()), brokerUser.trim(), brokerPass.trim(), useSSL ? 1 : 0)
                  .then(() => {
                    setOpenMQTT(false)
                    getInfo()
                      .then((res) => {
                        let update = {
                          name: res[0],
                          model: res[1],
                          serial: res[2],
                          hardware: res[3],
                          firmware: res[4],
                          software: res[5],
                          staticIp: res[6],
                          ip: res[7],
                          sub: res[8],
                          gateway: res[9],
                          dns: res[10],
                          mac: res[11],
                          brokerIP: res[12],
                          brokerPort: res[13],
                          brokerUser: res[14],
                          brokerPass: res[15],
                          agg: res[16],
                          calibrate: res[17],
                          convert: res[18],
                          s0Mode: res[19],
                          s0Edge: res[20],
                          s0Persist: res[21],
                          s0Period: res[22],
                          s0Calibrate: res[23],
                          s0Convert: res[24],
                          s1Mode: res[25],
                          s1Edge: res[26],
                          s1Persist: res[27],
                          s1Period: res[28],
                          s1Calibrate: res[29],
                          s1Convert: res[30],
                          s2Deadband: res[31],
                          s2Calibrate: res[32],
                          s2Convert: res[33],
                          s3Deadband: res[34],
                          s3Calibrate: res[35],
                          s3Convert: res[36],
                          wlanSSID: res[42],
                          wlanPass: res[43],
                          wlanFallback: res[44],
                          connectionType: res[45],
                          connectionQuality: res[46],
                          adoptionKey: res[47],
                          mqttUseSSL: res[48]

                        }
                        setSharcInfo(sharcInfo => ({
                          ...sharcInfo,
                          ...update
                        }))
                        setMQTTSettingsLoading(false)
                      })
                  })
              } else {
                setMQTTSettingsLoading(false)
                return;
              }
            }}>Update</button></div>
            <div className="reboot cancel"><button onClick={(e) => {
              setOpenMQTT(false)
            }}>Cancel</button></div>
          </div>
        </form>}
      </div>
    </div>
  )
}